/* Sidebar container */
.sidebar-container {
    width: 250px; /* Width of the sidebar */
    height: 100vh; /* Full height of the viewport */
    background: linear-gradient(145deg, #196ad3, #cbd5e1); /* Light 3D effect */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(255, 255, 255, 0.5); /* Shadow effect */
    padding: 20px; /* Padding inside the sidebar */
    display: flex; /* Flexbox layout */
    flex-direction: column; /* Arrange children in a column */
    align-items: center; /* Center align children */
    position: fixed; /* Fixed positioning */
    left: 0; /* Align to the left */
    top: 0; /* Align to the top */
    z-index: 1000; /* Ensure the sidebar is above other elements */
    margin: 10px;
}

/* 3D effect on user profile pic */
.user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px; /* Spacing below profile section */
}
.profile-pic {
    width: 100px; /* Size of the profile picture */
    height: 140px;
    border-radius: 25%; /* Circular shape */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3), -3px -3px 10px rgba(255, 255, 255, 0.5); /* Shadow effect */
    margin-bottom: 10px; /* Space below the picture */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation for hover */
    object-fit:cover; /* Ensures image fills the circle without distortion */
    background-color: #000000;
}


.profile-pic:hover {
    transform: scale(1.05); /* Scale up on hover */
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3), -3px -3px 10px rgba(255, 255, 255, 0.5); /* Increase shadow on hover */
}

.username {
    font-size: 1.2em; /* Font size for username */
    color: #ffffff; /* Text color */
    font-weight: bold; /* Bold text */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Search bar styles */
.search-bar {
    width: 100%; /* Full width */
    margin-bottom: 30px; /* Space below the search bar */
}

.search-input {
    width: 100%; /* Full width */
    padding: 10px 15px; /* Padding */
    border-radius: 25px; /* Rounded corners */
    border: none; /* No border */
    box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.1), inset -3px -3px 7px rgba(255, 255, 255, 0.5); /* Inner shadow */
    background: #f0f4f8; /* Background color */
    font-size: 1em; /* Font size */
    outline: none; /* No outline */
    transition: box-shadow 0.3s ease; /* Transition effect */
}

.search-input:focus {
    box-shadow: inset 7px 7px 15px rgba(0, 0, 0, 0.15), inset -4px -4px 10px rgba(255, 255, 255, 0.7); /* Shadow on focus */
}

/* Navigation links */
.navigation ul {
    list-style-type: none; /* Remove default list style */
    padding: 0; /* No padding */
}

.navigation li {
    margin-bottom: 10px; /* Space between navigation items */
    text-align: center;
}

.navigation li a {
    text-decoration: none; /* No underline */
    font-size: 0.85em; /* Font size */
    color: #000000; /* Text color */
    padding: 10px 10px; /* Padding */
    text-align: center;
   
    
    
}

.child-table th{
    color: #f0f4f8 !important;
    text-align: center;
    vertical-align: middle;
    font-size: 0.9rem;
}

.modal {
    display: flex;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 5px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.upload-icon {

    width: 50px;
    padding: 0;

    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px; /* Adjust size of the icon */
    box-shadow: none;
}

.upload-icon :hover{
    box-shadow: none;
    background: none;
    color: #000000;
}
/* Position logout button at the bottom */
.logout-button {
    width: 130px;
    margin-top: auto; /* Push this button to the bottom */
    margin-bottom: 75px;
    background: #e53e3e; /* Red background */
    color: white; /* White text */
    padding: 10px 10px; /* Padding */
    border: none; /* No border */
    border-radius: 20px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background 0.3s ease; /* Transition effect */
}

.logout-button:hover {
    background: #c53030; /* Darker red on hover */
}
.navigation li.active {
    background: rgb(255, 251, 0);
  }