/* src/components/CustomCheckbox.css */

.custom-checkbox-label {
 
    display: flex;
    align-items: center; /* Center align checkbox with label */
    cursor: pointer; /* Pointer cursor */
    margin-right: 10px; /* Space between checkboxes */
  }
  
  .custom-checkbox {
    display: none; /* Hide the native checkbox */
  }
  
  .checkbox-icon {
    width: 20px; /* Set width for icons */
    height: 20px; /* Set height for icons */
    margin-left: 5px; /* Space between label and icon */
    display: flex; /* Center the icon */
    align-items: center;
    justify-content: center;
    
    border-radius: 4px; /* Rounded corners */
    background-color: white; /* Background color */
  }
  
  .custom-checkbox:checked + .checkbox-icon {
    background-color: #007bff; /* Change background on check */
    color: white; /* Change color on check */
  }

  