.bonus-form-container {
    width: 1000px; /* Increased max width */
    margin: 0 auto;
    margin-bottom: 20px;
   
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.new-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-header {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
   
}

.form-section-header {
    font-size: 20px;
    margin: 20px 0 10px 0;
}

.form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Maintain gap between items */
    margin-bottom: 20px;
}
.vowahl{
    width: 50px;
}

/* Adjusting the input fields to allow for more items per row */
.form-input {
    flex: 1 1 calc(33% - 10px); /* Three items per row (adjust as needed) */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #292828;
    border-radius: 5px;
    box-sizing: border-box; /* Ensure padding is included in the width */
   
}

/* Full-width for email input */
.form-input[type='email'] {
    flex: 1; /* Full width */
    width: 80%;
    align-items: center;
}

/* Responsive adjustments */
@media (max-width: 900px) {
    .form-input {
        flex: 1 1 calc(50% - 10px); /* Two items per row on medium screens */
    }
}

@media (max-width: 600px) {
    .form-input {
        flex: 1 1 100%; /* Stack inputs on smaller screens */
    }
}

.checkbox-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.checkbox-label {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.form-checkbox {
    margin-right: 10px;
    font-size: 18px;
}

.submit-button {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 18px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #218838;
}

.head-Big{
    margin: auto;
    margin-top: 10%;
    text-align: center;
    color: rgb(255, 255, 255);
    background: #1811da;
}
.personal-info{
    display: flex;
    flex-direction: row;
    gap: 10px;

}

.signature-pad {
    border: 1px solid #ccc;
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
  }
