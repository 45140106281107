/* Magical Background */
.magical-bg {
  
    padding: 2rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
.in{
  width: 50%;
}
  .kind-det{
    font-size: 15px;
  }
  .class1{
    color: green;
  }
  .class2{
    color: red;
  }

  /* Magical Card Style */
  .magical-card {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 600px;
    width: 1000px;
    margin-bottom: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .magical-card:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15), 0 12px 24px rgba(0, 0, 0, 0.15);
  }
  
  /* Section Details */
  .sec-det {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
  }
  
  .sec-det p {
    margin: 0;
  }
  
  /* Files Section */
  .files-section {
    cursor: pointer;
    overflow: hidden;
  }
  
  .files-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: #555;
  }
  
  .files-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;
  }
  
  .files-container {
    max-height: 500px;
  }
  
  .file-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .file-item:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
  
  .file-icon {
    color: #ff6b6b;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .file-name {
    font-size: 1rem;
    color: #333;
  }
  
  .toggle-icon {
    color: #ff6b6b;
    font-size: 1.25rem;
  }
  
  /* Download Button */
  .download-button {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  .edit-buttons{
    text-align: center;
  }
 
  