/* Center the form both horizontally and vertically */
html, body {
    height: 100%; /* Make sure the body takes the full height */
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}

body {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
}

.register-container {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    border-radius: 20px;
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    box-shadow: 10px 10px 20px #d1d1d1, -10px -10px 20px #ffffff;
    transition: transform 0.3s ease-in-out;
    transform: translateZ(0);
    perspective: 1000px;
    text-align: center;
}

.register-container:hover {
    transform: translateY(-10px);
}

h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

form {
    width: 100%;
}

input {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border: none;
    border-radius: 30px;
    background: #f5f5f5;
    box-shadow: inset 5px 5px 10px #c9c9c9, inset -5px -5px 10px #ffffff;
    font-size: 16px;
    transition: box-shadow 0.3s ease-in-out;
}

input:focus {
    outline: none;
    box-shadow: inset 5px 5px 10px #bebebe, inset -5px -5px 10px #ffffff;
}

button {
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 5px 5px 15px rgba(0, 123, 255, 0.4), -5px -5px 15px rgba(0, 123, 255, 0.6);
    transition: all 0.3s ease-in-out;
}

button:hover {
    background: #0056b3;
    transform: translateY(-5px);
    box-shadow: 5px 5px 20px rgba(0, 123, 255, 0.6), -5px -5px 20px rgba(0, 123, 255, 0.7);
}

.error {
    color: red;
    text-align: center;
    margin-top: 10px;
}

.success {
    color: green;
    text-align: center;
    margin-top: 10px;
}

.fld{
    width: 95%;
}