/* General Styles */
body {
    margin-top: 5%;
}

/* Container styles */
.all-antrags-container-a {
    margin: 2rem auto;
    margin-top: 30%;
    max-width: 1700px; /* Container width */
    padding: 20px; /* Padding inside the container */
    background: #ffffff; /* Bright white background for the container */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    width: 90%;
}

/* Heading styles */
.all-antrags-container h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #3E4551;
    text-align: center;
    font-weight: 600; /* Bolder font weight */
}

/* Filter Card */
.filter-card {
    background-color: #f9f9f9; /* Light gray background for filter card */
    padding: 20px; /* Padding around filters */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-bottom: 20px; /* Space below the filter card */
}

/* Filter styles */
.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px; /* Space between inputs */
}

/* Input styles */
.filter-input {
    flex: 1; /* Allow inputs to grow */
    padding: 10px; /* Padding inside inputs */
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px; /* Rounded corners */
    outline: none; /* Remove outline on focus */
    transition: border-color 0.3s; /* Smooth transition for border color */
    text-align: center;
    font-size: 16px; /* Font size adjustment */
}

/* Change border color on focus */
.filter-input:focus {
    border-color: #007BFF; /* Blue border on focus */
}

/* Button styles */
.view-button, .delete-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-weight: 500;
}

.view-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.delete-button {
    font-size: 15px;
    background-color: #ff4d4d;
    width: 85%;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease; /* Transition for smooth hover effect */
}

.delete-button:hover {
    background-color: #ff3333;
}

/* Table wrapper for scrollable table */
.table-wrapper {
    max-height: 600px; /* Set max height for the scrollable table */
    overflow-y: auto; /* Enable vertical scrolling */
    border: 1px solid #ddd; /* Add a border around the table */
    border-radius: 8px; /* Rounded corners for table wrapper */
}

/* Table styles */
.antrags-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

/* Table header styles */
.antrags-table thead th {
    background-color: #007BFF;
    color: white;
    padding: 12px;
    font-weight: 500;
    position: sticky;
    top: 0; /* Keeps the header fixed */
    z-index: 1;
    font-size: 0.75rem;
}

/* Table cell styles */
.antrags-table td {
    text-align: center;
    border: 2px solid #ddd;
    padding: 4px;
    transition: background-color 0.3s; /* Smooth transition for background */
    font-size: 0.7rem;
}

/* Row hover effect */
.antrags-table tr:hover {
    background-color: #f1f1f1;
}

/* Responsive styles */
@media (max-width: 600px) {
    .my-antrags-container {
        padding: 15px;
    }

    .my-antrags-container h2 {
        font-size: 2rem;
    }

    .antrags-table th, .antrags-table td {
        padding: 8px;
    }

    .filter-input {
        font-size: 14px; /* Smaller font for mobile */
        padding: 8px; /* Less padding for mobile */
    }
}

/* Scrollbar style (optional) */
.table-wrapper::-webkit-scrollbar {
    width: 8px;
}

.table-wrapper::-webkit-scrollbar-thumb {
    background-color: #007BFF;
    border-radius: 4px;
}

.table-wrapper::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Animation for count */
.digital-counter {
    font-size: 20px;
    margin: 0 10px;
    font-weight: bold; /* Make count bolder */
    transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.digital-counter-animate {
    transform: scale(1.1); /* Scale effect on animation */
}
.sorting-buttons {
    display: flex;
    justify-content: flex-start; /* Align buttons to the start */
    margin-bottom: 10px; /* Spacing below the buttons */
}

.sort-button {
    background-color: #4CAF50; /* Green background */
    border: none; /* No border */
    color: white; /* White text */
    padding: 10px 15px; /* Padding around the button */
    margin-right: 5px; /* Space between buttons */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 5px; /* Rounded corners */
    font-size: 14px; /* Font size */
}

.sort-button:hover {
    background-color: #45a049; /* Darker green on hover */
}

.arrow {
    margin: 0 10px; /* Space around the arrow */
    font-size: 20px; /* Arrow size */
}

.digital-counter {
    font-size: 24px; /* Counter font size */
}

.digital-counter-animate {
 animation: pulse 1s infinite; /* Add animation for pulse effect */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
.sorting-buttons {
    display: flex;
    justify-content: center; /* Center the buttons */
    margin: 20px 0; /* Add some spacing above and below */
}

.sort-button {
    background-color: #55a9a9; /* Primary color */
    width: 300px;
    color: white; /* Text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px; /* Padding for better size */
    margin: 0 10px; /* Space between buttons */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
}

/* Hover effect */
.sort-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
    transform: translateY(-2px); /* Slight lift effect */
}

