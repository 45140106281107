/* Main container with high-resolution shadows and refined styling */
.login-container {
    width: 450px;
    padding: 40px;
    margin: 80px auto;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    border-radius: 20px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.15), -10px -10px 20px rgb(255, 255, 255);
    text-align: center;
    transform: perspective(700px) rotateX(6deg) rotateY(-4deg);
    transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
}

.log-h{
    font-size: 36px;
}

.login-container:hover {
    transform: perspective(700px) rotateX(0deg) rotateY(0deg) scale(1);
    
}

/* Title with font smoothing */
.login-container h2 {
    font-size: 26px;
    color: #4a4a4a;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    font-family: 'Comic Sans MS', cursive, sans-serif;
    font-smooth: always;

    margin-bottom: 25px;
}

/* Input fields with refined shadow and padding */
.login-container input[type="text"],
.login-container input[type="password"] {
    width: 85%;
    padding: 14px;
    margin: 12px 0;
    font-size: 19px;
    border-radius: 12px;
    border: none;
    background: #f3f3f3;
   
    font-family: 'Comic Sans MS', cursive, sans-serif;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.login-container input[type="text"]:focus,
.login-container input[type="password"]:focus {
 
    outline: none;
    transform: scale(1.02);
}

/* Enhanced button with sharper shadows */
.login-container button[type="submit"] {
    width: 100%;
    padding: 14px;
    font-size: 19px;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(145deg, #6423e7, #4d9eef);
    border: none;
    border-radius: 12px;
    cursor: pointer;
    
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family: 'Comic Sans MS', cursive, sans-serif;
}

.login-container button[type="submit"]:hover {
    transform: translateY(-3px);
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3), -8px -8px 20px rgba(255, 255, 255, 0.9);
}

.login-container button[type="submit"]:active {
    transform: translateY(1px);
    box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.2), inset -4px -4px 8px rgba(255, 255, 255, 0.7);
}

/* Error message styling */
.error {
    color: #e63946;
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1);
}
