/* General styles for the dashboard container */


.dashboard-container {
    max-width: 100%; /* Full width */
    margin: 0 auto;  /* Center container */
    padding: 40px;
    border-radius: 20px;
    background: linear-gradient(135deg, #ece9e6, #ffffff); /* Subtle gradient */
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2); /* Stronger shadow for 3D effect */
    position: relative;
    overflow: hidden;
    font-family: 'Poppins', sans-serif; /* Modern font */
}

/* Styles for the dashboard heading */
.dashboard-container h2 {
    text-align: center;
    font-size: 2.8rem; /* Larger font size */
    color: #333; /* Dark text color */
    margin-bottom: 30px;
    letter-spacing: 1.5px; /* Increased spacing */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle text shadow */
}

/* Button styles */
button {
    padding: 12px 25px;
    margin: 15px 0;
    border: none;
    border-radius: 12px; /* Rounded corners */
    background: linear-gradient(45deg, #42a5f5, #1e88e5); /* Gradient background */
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3s ease;
    box-shadow: 0 8px 20px rgba(66, 165, 245, 0.3); /* Soft shadow for 3D look */
}

/* Button hover effect */
button:hover {
    background: linear-gradient(45deg, #1e88e5, #0d47a1); /* Darker gradient on hover */
    transform: translateY(-4px); /* Slight lift on hover */
    box-shadow: 0 12px 30px rgba(66, 165, 245, 0.4); /* Increased shadow on hover */
}

/* Form styles */
form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: auto;
}

/* Input styles */
input[type="text"],
input[type="file"] {
    padding: 15px;
    margin: 5px 0;
    
    border-radius: 10px; /* Rounded corners */
    background-color: rgba(255, 255, 255, 0.8); /* Light background */
    font-size: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Light shadow */
    transition: box-shadow 0.3s ease; /* Transition for shadow */
  
}
.vowahl{
    width: 40px;
}

/* Input focus effect */
input[type="text"]:focus,
input[type="file"]:focus {
    box-shadow: 0 4px 20px rgba(66, 165, 245, 0.3); /* Shadow on focus */
    outline: none; /* Remove outline */
}

/* Success and error message styles */
.success {
    color: #4caf50;
    background-color: #e8f5e9;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin-top: 15px;
    font-size: 1rem;
}

.error {
    color: #f44336;
    background-color: #ffebee;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin-top: 15px;
    font-size: 1rem;
}

/* Table styles */
table {
    width: 100%; /* Full width */
    border-collapse: collapse; /* Remove space between cells */
    margin-top: 30px; /* Space above the table */
    font-size: 1rem; /* Font size */
    background-color: rgba(255, 255, 255, 0.9); /* Light background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

/* Table header styles */
th, td {
    padding: 15px 20px; /* Padding inside cells */
    text-align: left; /* Align text */
}

/* Header styles */
th {
    background: linear-gradient(45deg, #42a5f5, #1e88e5); /* Gradient background for header */
    color: white; /* Text color */
    text-transform: uppercase; /* Uppercase for header text */
    letter-spacing: 0.05rem; /* Spacing */
}

/* Cell styles */
td {
    border-bottom: 1px solid #ddd; /* Bottom border */
}

/* Row hover effect */
tbody tr:hover {
    background-color: rgba(66, 165, 245, 0.2); /* Highlight on hover */
    transition: background-color 0.3s ease; /* Smooth transition */
}

/* Responsive design */
@media (max-width: 768px) {
    .dashboard-container {
        padding: 20px; /* Padding adjustments */
    }

    th, td {
        padding: 12px 15px; /* Padding adjustments */
    }

    table {
        font-size: 0.9rem; /* Smaller font size */
    }

    button {
        width: 100%; /* Full width buttons */
    }
}

@media (max-width: 500px) {
    .dashboard-container {
        padding: 15px; /* Further padding adjustments */
    }

    th, td {
        padding: 10px 12px; /* Further padding adjustments */
    }

    button {
        padding: 10px; /* Adjust button padding */
    }

    h2 {
        font-size: 2rem; /* Smaller heading */
    }
}

/* Ensure the dashboard is scrollable */
.dashboard-container {
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: calc(100vh - 100px); /* Prevent exceeding viewport height */
}
