.ticket-detail {
    margin: 20px auto;
    padding: 20px;
    width: 1200px;
    max-width: 1200px; /* Restrict the maximum width */
    border-radius: 10px;
    background: hsl(223, 7%, 81%);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    height: 90vh;
    overflow-y: auto;
    word-wrap: break-word; /* Ensure long content wraps */
}

.ticket-title {
    text-align: center;
    font-size: 1.5em;
    color: #2c3e50;
    margin-bottom: 10px;
}

.ticket-info {
    background: linear-gradient(135deg, #ffffff 0%, #437a94 100%);
    border-radius: 12px;
    text-align: center;
    padding: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;
    position: relative;
    transition: transform 0.3s, box-shadow 0.3s;
    word-wrap: break-word; /* Ensure long content wraps */
    max-width: 100%;
}

.ticket-info p {
    margin: 5px 0;
    color: #2c3e50;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.15);
    letter-spacing: 0.5px;
}

.ticket-info:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15), 0 4px 20px rgba(0, 0, 0, 0.1);
}

.replies-title {
    font-size: 1.2em;
    color: #2980b9;
    margin-bottom: 10px;
    padding-top: 20px;
}

.replies {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    flex-grow: 1;
    overflow-y: auto;
}

.reply {
    font-size: 18px;
    border-radius: 10px;
    padding: 10px 15px;
    max-width: 75%; /* Prevent replies from exceeding the container */
    word-wrap: break-word; /* Ensure long messages wrap */
    position: relative;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.sent {
    align-self: flex-end;
    background: linear-gradient(to right, #b8c5b8, #f0f5fa);
    color: rgb(0, 0, 0);
    border-top-right-radius: 0;
}

.received {
    align-self: flex-start;
    background: #f0f8ff;
    color: black;
    border-top-left-radius: 0;
}

.reply-author {
    margin: 0;
    font-weight: bold;
    margin-bottom: 5px;
    color: #34495e;
}

.reply-message {
    margin: 5px 0;
    word-wrap: break-word; /* Ensure long words or URLs wrap */
}

.reply-timestamp {
    font-size: 0.8em;
    color: #404040;
    text-align: right;
    margin-top: 5px;
    display: block;
}

.rec {
    color: #000000;
}

.reply-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.reply-input {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    resize: none;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
    word-wrap: break-word; /* Ensure input wraps long content */
    height: auto; /* Allow auto height for textarea */
    min-height: 50px; /* Minimum height for user comfort */
}

/* Change to textarea */
.reply-input {
    overflow: hidden; /* Prevent scrollbar from showing */
}

.reply-input:focus {
    border-color: #007bff;
    outline: none;
}

.file-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.file-input {
    display: none;
}

.file-input-label {
    width: 200px;
    background-color: #25D366;
    color: white;
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    font-size: 0.9em;
    transition: background-color 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.file-input-label:hover {
    background-color: #1EBE57;
}

.file-input-label i {
    margin-right: 8px;
    font-size: 1.1em;
}

.reply-button {
    width: 200px;
    padding: 10px 15px;
    text-align: center;
    background: #25D366;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.reply-button:hover {
    background: #1EBE57;
}

.reply-files {
    width: 100%; /* Ensure the table uses full width */
    margin-top: 10px; /* Add spacing above the files table */
}

.reply-files table {
    width: 100%;
    border-collapse: collapse; /* Remove spacing between cells */
}

.reply-files th,
.reply-files td {
    background-color: rosybrown;
    padding: 10px;
    text-align: center; /* Align text to the left */
    border-bottom: 1px solid #000000; /* Light border for rows */
    align-items: center;
}

.reply-files th {
    background-color: #f4f4f4; /* Light gray background for header */
    color: #000000; /* Header text color */
}

.reply-files tr:hover {
    background-color: #f1f1f1; /* Change background on hover */
}

.file-name {
    margin-left: 5px; /* Add some space between the icon and file name */
    color: #000000;
}

.loading,
.error {
    text-align: center;
    font-size: 1.2em;
    color: #e74c3c;
}

/* Selected files list */
.selected-files ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}

.selected-files li {
    display: flex;
    align-items: center;
    padding: 5px 8px;
    margin-bottom: 5px;
    background-color: #f4f4f4; /* Light gray background */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow for file box */
    max-width: 400px; /* Restrict width if needed */
}

.selected-files li i {
    margin-right: 6px;
    font-size: 1.1em;
    color: #007bff; /* Icon color */
}

.selected-files li .file-name {
    flex-grow: 1; /* File name takes up remaining space */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate long filenames */
    margin-right: 10px; /* Space between file name and remove icon */
}

.remove-icon {
    font-size: 1.1em;
    color: red;
    cursor: pointer;
    transition: color 0.3s;
}

.remove-icon:hover {
    color: darkred;
}

/* Textarea specific styles */
.reply-input {
    min-height: 50px; /* Minimum height for better UX */
    max-height: 200px; /* Limit height to avoid overflow */
    overflow: hidden; /* Hide the overflow */
    padding: 10px; /* Padding inside the textarea */
    border: 1px solid #ddd; /* Border style */
    border-radius: 8px; /* Rounded corners */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

/* Expandable textarea on input */
.reply-input:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove default outline */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add box shadow on focus */
}
