.add-client-form {
    max-width: 1200px;
    margin: auto;
  }
  .modal-content{
    margin-top: 0 !important;
    width: 57%  !important;
    max-width: 100%  !important;
  }
  
  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group select {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .child-fields {
    display: flex;
    gap: 10px; /* Space between child fields */
    align-items: center;
    margin-bottom: 15px;
  }
  
  .child-fields label {
    font-weight: normal;
  }
  
  .child-fields input {
    padding: 5px;
    font-size: 14px;
    width: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-btn {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }
  
  .new-personal {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 80px; /* Spacing between elements */
    justify-content: center;
    
    /* 3D borders and shadow */
    padding: 20px; /* Add some padding for spacing inside */
    border-radius: 12px; /* Rounded corners for a modern look */
    background: linear-gradient(145deg, #ffffff, #e6e6e6); /* Soft gradient for depth */
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), 
                -4px -4px 10px rgba(255, 255, 255, 0.7); /* Dual shadows for a 3D effect */
    
    /* Optional: Add transition for hover effect */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.new-personal:hover {
    box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.2), 
                -6px -6px 15px rgba(255, 255, 255, 0.8);
    transform: translateY(-3px); /* Slight pop-out effect */
}

/* Individual elements inside */
.new-personal input, .new-personal button {
    padding: 10px 15px;
    border: 1px black solid;
    border-radius: 8px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1), 
                inset -2px -2px 5px rgba(255, 255, 255, 0.7);
    font-size: 16px;
    transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.new-personal input:focus, .new-personal button:hover {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 
                -2px -2px 8px rgba(255, 255, 255, 0.8);
    transform: translateY(-2px);
}





/*
  .new-personal{

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 80px; 
   
    justify-content: center;

      
  }*/
  .new-personal1{

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 80px; /* Spacing between elements */
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    justify-content: center;

      
  }
  .magical-bg, .new-personal, .new-personal1 {
    display: flex;
    align-items: center;
    justify-content: center;
  
    gap: 50px; /* Optional: adds space between items */
}

 .new-personal > div, .new-personal1 > div {
    flex: 1 1 22%; /* Make each item take up to 23% of the container width */
    max-width: 23%; /* Ensure no item exceeds 23% width */
    box-sizing: border-box; /* Include padding and border in the width */
}