.famielien-form-container {
 left: 17%;
    position: fixed;
    top: 0; /* Fixes the top position */
    /* width: 70%; Adjust width as needed */
    max-height: 100vh; /* Prevents overflow beyond the viewport */
    overflow-y: auto; /* Allows scrolling down if the content is too long */
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    scrollbar-width: none; /* Firefox */
    height: 100%;
}
.famielien-form-container::-webkit-scrollbar {
    display: none;
}

p{
    font-size: 1rem;
}

.new-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-header {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
   
}

.form-section-header {
    font-size: 20px;
    margin: auto;
    background: #fff700;
    width: fit-content;
    text-align: center;
  
}

.form-section-header1 {
   padding: 10px;
    color: #e6e6e6;
    font-size: 20px;
    margin: auto;
    background: #920000;
    width: fit-content;
    text-align: center;
    border-radius: 12px;
  
}

.form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Maintain gap between items */
    margin-bottom: 20px;
}
.vowahl{
    width: 50px;
}

/* Adjusting the input fields to allow for more items per row */
.form-input {
    flex: 1 1 calc(33% - 10px); /* Three items per row (adjust as needed) */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #292828;
    border-radius: 5px;
    box-sizing: border-box; /* Ensure padding is included in the width */
   
}

/* Full-width for email input */
.form-input[type='email'] {
    flex: 1; /* Full width */
    width: 80%;
    align-items: center;
}

/* Responsive adjustments */
@media (max-width: 900px) {
    .form-input {
        flex: 1 1 calc(50% - 10px); /* Two items per row on medium screens */
    }
}

@media (max-width: 600px) {
    .form-input {
        flex: 1 1 100%; /* Stack inputs on smaller screens */
    }
}

.checkbox-group {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.checkbox-label {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.form-checkbox {
    margin-right: 10px;
    font-size: 18px;
}

.submit-button {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 18px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #218838;
}

.head-Big{
    margin: auto;
    margin-top: 10%;
    text-align: center;
    color: rgb(255, 255, 255);
    background: #1811da;
}
.personal-info{
    
    max-width: 1300px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    

}
.personal-info1{
    
    max-width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border: 1px #000;
    
    

}
.person1{
    max-width: 1100px;
    padding: 10px;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 20px;
    font: 1em sans-serif bold;
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    align-items: center;
    justify-content: center;

}
.person {
    max-width: 470px;
    padding: 15px;
    border: 2px solid transparent;
    border-radius: 20px;
    font: 1em sans-serif bold;
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    box-shadow: 
        0 10px 20px rgba(0, 0, 0, 0.2), /* Subtle outer shadow */
        0 6px 8px rgba(0, 0, 0, 0.1),   /* Secondary shadow for depth */
        inset 0 1px 4px rgba(255, 255, 255, 0.5), /* Inner light shadow */
        inset 0 -4px 12px rgba(0, 0, 255, 0.2);  /* Inner soft glowing shadow */

    background: linear-gradient(145deg, #dbdbff, #c7c7ff);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.person:hover {
    transform: translateY(-10px); /* Lift effect on hover */
    box-shadow: 
        0 20px 40px rgba(0, 0, 0, 0.3), /* Stronger outer shadow */
        inset 0 4px 10px rgba(255, 255, 255, 0.6), /* Enhanced inner glow */
        inset 0 -8px 20px rgba(0, 0, 255, 0.3); /* Enhanced inner glow */
    background: linear-gradient(145deg, #ffe100, #ffe6ff); /* Magic-like glow effect */
}




.signature-pad {
    border: 1px solid #ccc;
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
  }

  .namen{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
  }
 .select-dropdown {
    height: 20px;
    position: relative;
    display: inline-block;
   margin: auto;
   margin-bottom: 10px;
   margin-top: 10px;
    max-width: 400px;
    background: linear-gradient(145deg, #e0e0ff, #f9f9ff);
    padding: 12px;
    border-radius: 12px;
    font-family: sans-serif;
    font-size: 1em;
    color: #1a1a66;
    cursor: pointer;
    border: 2px solid #1e00ff;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2), 
                inset -3px -3px 10px rgba(255, 255, 255, 0.6), 
                inset 3px 3px 5px rgba(0, 0, 255, 0.15);
    transition: all 0.3s ease;
}

.select-dropdown:hover {
    background: linear-gradient(145deg, #ffffff, #d4d4ff);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 
                inset -3px -3px 15px rgba(255, 255, 255, 0.7), 
                inset 3px 3px 10px rgba(0, 0, 255, 0.25);
}

.select-dropdown select {
    
    width: 100%;
    padding: 0px;
    padding-right: 20px;
    border: none;
    background: transparent;
    color: #1a1a66;
    font-size: 1em;
    outline: none;
    cursor: pointer;
    appearance: none; /* Hide default arrow */
}

.select-dropdown select option {
    background: #ffffff;
    color: #000;
    font-family: sans-serif;
    font-size: 1em;
    text-align: center;
}

/* Custom arrow styling */
.select-dropdown::after {
    content: '▼';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.8em;
    color: #1a1a66;
    pointer-events: none;
}

.select-dropdown select:focus + .select-dropdown::after {
    color: #000;
}
.label-Big{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: 500;
}
/* ClientForm.css */
.client-options {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px; /* Set max height */
    overflow-y: auto; /* Add scroll if too many options */
    position: absolute; /* Positioning dropdown */
    background-color: white; /* Background for dropdown */
    z-index: 1000; /* Make sure it's on top */
  }
  
  .client-options li {
    padding: 10px; /* Spacing for each option */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.2s; /* Smooth background change */
  }
  
  .client-options li:hover {
    background-color: #f0f0f0; /* Highlight on hover */
  }
  
  .radio-options label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.radio-options input[type="radio"] {
    margin-right: 8px;
}

.radio-options label:hover {
    background-color: #f2f2f2;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
}
.radio{
    color: #920000;
    width: 20px;
    height: 20px;
    cursor: pointer;

}
.radio :active{
    background-color: #1811da;
}