/* Reset some default styles */

/* Body styling */
body {
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(to right, #ffffff, #80a6ff);
    color: #333;
    line-height: 1.6;
}

/* Main container for the Antrag component */
.container {
    width: 90%; /* Changed from fixed width to relative for smaller screens */
    max-width: 1000px;
    margin: 10% auto; /* Center the container on smaller devices */
    margin-top: 100%;;
    padding: 30px; /* Adjusted padding */
    background: white;
    border-radius: 12px;
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
}

/* Scale up on hover */
.container:hover {
    box-shadow: 0 40px 80px rgba(0, 0, 0, 0.3);
}

/* Header styles */
h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #4a90e2;
    text-align: center;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
}

/* Form elements styling */
form {
    display: flex;
    flex-direction: column;
}

/* Input fields styling */
input, select, button {
    color: #333;
    margin-bottom: 1.5rem;
    padding: 12px;
    font-size: 1.1rem;
    border: 1px solid #ddd;
    border-radius: 6px;
    transition: border 0.3s, box-shadow 0.3s;
    width: 70%;
}

/* Focus effect for input fields */
input:focus, select:focus {
    border-color: #4a90e2;
    outline: none;
    box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

/* Button styling */
button {
    background: #4a90e2;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    font-weight: bold;
    padding: 12px;
    font-size: 1.1rem;
    transition: background-color 0.3s, transform 0.2s;
}

/* Button hover effect */
button:hover {
    background: #357ABD;
    transform: translateY(-2px);
}

/* Small text styling */
small {
    font-size: 0.9rem;
    color: #666;
}

/* List styles for existing Antrags */
ul {
    list-style: none;
    padding: 0;
    margin-top: 1.5rem;
}

/* Individual list item styling */
li {
    padding: 12px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-bottom: 10px;
    transition: background 0.3s, box-shadow 0.3s;
}

/* Highlight on hover for list items */
li:hover {
    background: #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Modal styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 12px;
    width: 500px;
    max-width: 90%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
    position: relative;
    text-align: center;
    color: #333;
    font-family: 'Roboto', sans-serif;
}

.modal-content h2 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: #4a90e2;
}

/* Modal footer styles */
.modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.confirm-btn, .cancel-btn {
    padding: 12px 20px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;
}

.confirm-btn {
    background-color: #28a745;
    color: white;
}

.cancel-btn {
    background-color: #dc3545;
    color: white;
}

.confirm-btn:hover {
    background-color: #218838;
    transform: translateY(-1px);
}

.cancel-btn:hover {
    background-color: #c82333;
    transform: translateY(-1px);
}

/* --- Media Queries for Responsive Design --- */

/* Target devices from 600px (small tablets and up) */
@media (max-width: 1050px) {
    .container {
        width: 90%;
        padding: 20px; /* Reduce padding */
       
    }

    h2 {
        font-size: 2rem; /* Reduce header size */
    }

    button {
        font-size: 1rem; /* Slightly reduce button font size */
    }

    .modal-content {
        width: 80%; /* Scale modal down for smaller screens */
    }
}

/* For screens less than 768px (small tablets) */
@media (max-width: 768px) {
    .container {
        width: 100%;
        margin: 5% auto; /* Less margin for smaller screens */
        padding: 15px; /* Reduce padding */
    }

    h2 {
        font-size: 1.8rem; /* Further reduce header size */
    }

    input, select, button {
        font-size: 1rem; /* Reduce input and button font size */
        padding: 10px;
    }

    button {
        font-size: 0.9rem; /* Smaller font size for buttons */
    }

    .modal-content {
        padding: 20px;
    }
}

/* For very small screens, like smartphones (less than 600px) */
@media (max-width: 600px) {
    .container {
        padding: 10px; /* Minimize padding */
        margin: 10% auto;
    }

    h2 {
        font-size: 1.5rem; /* Smaller font size */
    }

    input, select, button {
        font-size: 0.8rem; /* Adjust font size for inputs and buttons */
        padding: 8px;
    }

    .modal-content {
        padding: 15px;
        width: 95%; /* Full width for modal */
    }
}
