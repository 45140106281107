/* MyAntrags.css */

/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* General Styles */
body {
    margin-top: -35%;
    font-family: 'Roboto', sans-serif; /* Use Roboto font for the entire body */
    background-color: #f4f6f9; /* Light background for the overall page */
    color: #333; /* Dark text color for readability */
}

.table-wrapper1 {
    max-height: 450px; /* Set max height for the scrollable table */
    overflow-y: auto; /* Enable vertical scrolling */
    border: 1px solid #ddd; /* Add a border around the table */
}

/* Container styles */
.my-antrags-container {
    
    width: 1200px; /* Container width */
    margin: 2rem auto; /* Center the container */
    /* margin-top: 10%; */
    padding: 20px; /* Padding inside the container */
    background: #ffffff; /* Bright white background for the container */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* Heading styles */
.my-antrags-container h2 {
    font-size: 2.5rem; /* Increased heading size */
    margin-bottom: 1.5rem; /* Space below the heading */
    color: #3E4551; /* Darker shade for the header */
    text-align: center; /* Center the heading */
}

/* Table styles */
.antrags-table {
    width: 100%; /* Full width of the container */
    border-collapse: collapse; /* Remove gaps between table cells */
    text-align: center; /* Center the heading */
}

/* Table header styles */
.antrags-table th {
    background-color: #007BFF; /* Header background color */
    color: white; /* White text color */
    padding: 12px; /* Padding inside table headers */
    text-align: center; /* Align text to the left */
    font-weight: 500; /* Medium font weight */
}

/* Table cell styles */
.antrags-table td {
    text-align: center;
    border: 1px solid #ddd; /* Light gray border */
    padding: 12px; /* Padding inside table cells */
}

/* Row hover effect */
.antrags-table tr:hover {
    background-color: #f1f1f1; /* Light gray background on row hover */
}

/* Button styles */
.view-button, .delete-button {
    background-color: #007BFF; /* Primary button color */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 10px 15px; /* Padding for buttons */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
    font-weight: 500; /* Medium font weight */
    display: flex; /* Flex display for icons */
    align-items: center; /* Center icons vertically */
}

/* Button hover effects */
.view-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift effect */
}

.delete-button {
    background-color: #dc3545; /* Red for delete */
    margin-left: 10px; /* Space between buttons */
}

.delete-button:hover {
    background-color: #c82333; /* Darker red on hover */
}

/* Loading and Error styles */
.loading, .error {
    text-align: center; /* Centered text for loading/error states */
    font-size: 1.5rem; /* Font size for loading/error messages */
    color: #777; /* Grey text color */
    margin: 2rem; /* Space around the messages */
}

/* Sorting controls styles */
.sorting-controls {
    display: flex; /* Flexbox layout */
    justify-content: center; /* Center the controls */
    align-items: center; /* Align items vertically */
    margin-bottom: 1.5rem; /* Space below sorting controls */
}

.sorting-controls label {
    margin-right: 10px; /* Space between label and dropdown */
    font-weight: bold; /* Bold label text */
}

/* Dropdown styles */
.sorting-controls select {
    padding: 10px; /* Padding for dropdowns */
    margin: 0 5px; /* Space around dropdowns */
    border-radius: 5px; /* Rounded corners */
    border: 1px solid #ddd; /* Light gray border */
    font-size: 1rem; /* Font size */
    background-color: #fff; /* White background */
    transition: border-color 0.3s ease; /* Smooth border color transition */
}

.sorting-controls select:focus {
    border-color: #007BFF; /* Highlight border color on focus */
    outline: none; /* Remove outline */
}

/* Responsive styles */
@media (max-width: 600px) {
    .my-antrags-container {
        padding: 15px; /* Reduced padding on smaller screens */
    }

    .my-antrags-container h2 {
        font-size: 2rem; /* Smaller heading size */
    }

    .antrags-table th, .antrags-table td {
        padding: 8px; /* Reduced padding for smaller screens */
    }

    /* Sorting controls for smaller screens */
    .sorting-controls {
        flex-direction: column; /* Stack controls vertically */
        align-items: flex-start; /* Align controls to the left */
    }

    .sorting-controls label {
        margin-bottom: 5px; /* Space below label */
    }
}

/* Style for the number of Aufträge with modern 3D animation */
/* Container for both counters */
.counter-wrapper {
    display: flex;
    align-items: center; /* Vertically align items */
    justify-content: center; /* Horizontally center the items */
    margin-bottom: 2rem; /* Add space below the counters */
    position: relative; /* For positioning the arrow */
}

/* Arrow between counters */
.arrow {
    font-size: 2rem; /* Increase size of the arrow */
    color: #007BFF; /* Match the arrow color to the counters */
    margin: 0 1rem; /* Space around the arrow */
}

/* Style for the number of Aufträge with modern 3D animation */
.digital-counter {
    font-family: 'Courier New', Courier, monospace; /* Monospace font for digital feel */
    font-size: 1.5rem; /* Larger font size for prominence */
    font-weight: bold; /* Bold to emphasize the number */
    color: #ffffff; /* White color */
    background-color: #007BFF; /* Blue background for a modern look */
    padding: 10px 20px; /* Padding around the number */
    border-radius: 8px; /* Slight rounding for a modern touch */
    text-align: center; /* Center the text */
    box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.5); /* Subtle shadow */
    letter-spacing: 0.1rem; /* Slight spacing for a digital feel */
    display: inline-block; /* Inline block to adjust sizing */
    perspective: 1000px; /* Add depth for 3D effect */
    margin: 0 auto; /* Centered text */
}

/* Animation class for smooth 3D scaling and fading effect */
.digital-counter-animate {
    animation: smooth3d 1s ease-in-out forwards; /* Trigger the smooth animation */
    -webkit-animation: smooth3d 1s ease-in-out forwards; /* Chrome/Webkit specific animation */
    transform-style: preserve-3d; /* Allow 3D transformations */
    -webkit-transform-style: preserve-3d; /* Chrome/Webkit specific */
}

/* Keyframes for the smooth 3D effect */
@keyframes smooth3d {
    0% {
        transform: scale(0.8) rotateX(0deg) rotateY(0deg); /* Start small and flat */
        opacity: 0.5; /* Semi-transparent */
    }
    50% {
        transform: scale(1.1) rotateX(15deg) rotateY(15deg); /* Enlarge slightly and rotate */
        opacity: 0.8; /* Become more opaque */
    }
    100% {
        transform: scale(1) rotateX(0deg) rotateY(0deg); /* End with normal size and flat */
        opacity: 1; /* Fully opaque */
    }
}

/* Chrome/Webkit specific keyframes */
@-webkit-keyframes smooth3d {
    0% {
        -webkit-transform: scale(0.8) rotateX(0deg) rotateY(0deg); /* Start small and flat */
        opacity: 0.5; /* Semi-transparent */
    }
    50% {
        -webkit-transform: scale(1.1) rotateX(15deg) rotateY(15deg); /* Enlarge slightly and rotate */
        opacity: 0.8; /* Become more opaque */
    }
    100% {
        -webkit-transform: scale(1) rotateX(0deg) rotateY(0deg); /* End with normal size and flat */
        opacity: 1; /* Fully opaque */
    }
}

/* Vendor Prefixes for smoother transitions across older Chrome versions */
.digital-counter {
    -webkit-transition: all 0.3s ease-in-out; /* Older Chrome/Webkit transition */
    -moz-transition: all 0.3s ease-in-out;   /* Firefox transition */
    transition: all 0.3s ease-in-out;        /* Modern browsers */
}

.digital-counter-animate {
    -webkit-transform-style: preserve-3d; /* Ensure 3D in older Chrome/Webkit */
    transform-style: preserve-3d;
}
