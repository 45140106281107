

/* Form Container */
.form {
  
    background-color: #fff;
    padding: 3rem;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
   
    width: 90%;
    position: relative;
    overflow: hidden;
}

/* Title Style */
.form h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
}

/* Form Fields */
.form-field {
    border:1px solid gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.pdf-form input[type="text"],
.pdf-form input[type="email"] {
    width: 100%; /* Adjust width to fit with label */
    padding: 1rem;
    border:1px solid gray;
    border-radius: 8px;
    font-size: 1rem;
    background-color: #f9f9f9;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
    margin: auto;
}

.form-field input[type="text"]:focus,
.form-field input[type="email"]:focus {
    outline: none;
    box-shadow: 0 4px 20px rgba(108, 99, 255, 0.5);
}

/* Checkbox Styles */
form label {
    font-size:17px;
    color: #333;
    font-weight: 500;
    margin-right: 1rem; /* Space between label and checkbox */
}

form input[type="checkbox"] {
    margin-right: 0.5rem;
    font-size: 20px;
}

/* Submit Button */
form button {
    background-color: #6c63ff;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 0.75rem;
    font-size: 1rem;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 1.5rem;
    box-shadow: 0 4px 20px rgba(108, 99, 255, 0.3);
}

form button:hover {
    background-color: #5a54d6;
    transform: translateY(-2px);
}

form button:active {
    transform: translateY(0);
    box-shadow: 0 2px 10px rgba(108, 99, 255, 0.5);
}

/* Media Queries */
@media (max-width: 600px) {
    form {
        padding: 2rem;
    }

    .form-field {
        flex-direction: column;
        align-items: flex-start;
    }

    .form-field input[type="text"],
    .form-field input[type="email"] {
        width: 100%; /* Full width on smaller screens */
    }
}
.pdf-form {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: flex;
    flex-direction: column;
    max-width: 80%; /* Set a maximum width for better alignment */
    
  }
  .pdf-form h1 {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
color: #ffffff;
background-color: orange;
  }
  
  .form-field {
    border:1px solid gray;
    display: flex;
    justify-content: space-between; /* Space out the fields */
    margin-bottom: 10px; /* Add some space between rows */
    
  }
  
  .form-field input {
    border:1px solid gray;
    flex: 1; /* Allow the inputs to take equal space */
    margin-right: 10px; /* Space between inputs */
    
  }
  
  .form-field input:last-child {
    margin-right: 0; /* Remove margin from the last input */
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap; /* Allow checkboxes to wrap */
    gap: 50px; /* Space between checkboxes */
  }

  .checkbox-group label {
    font-family:Arial, Helvetica, sans-serif;
 font-size: 20px;
 font-weight: 100;
    background: linear-gradient(to top, #8248de 10%, transparent 10%), 
   
   
    radial-gradient(circle at 60% 100%, transparent 10%, #fff3b2 21%);
    padding: 10px;
    color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(202, 180, 106, 0.5);
}



  
  button {
    margin-top: 20px; /* Space above the button */
    padding: 10px 20px; /* Button padding */
    background-color: #007bff; /* Button color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
  }
  
  button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  .row-2fileds {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; /* Fixed from justify-items */
    gap: 10px;
   
}
  .row-3fileds {
    margin: 5px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; /* Fixed from justify-items */
    gap: 10px;
   
}
.row-3fileds > * { /* Applies to all direct children */
    margin-right: 10px; /* Set margin for each child */
   
    margin: 10px; /* Space around each item */
}


  .row-2check {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; /* Fixed from justify-items */
    gap: 5px;
    flex-wrap: wrap;
}

.row-2check > * { /* Applies to all direct children */
    margin-right: 10px; /* Set margin for each child */
    width:3 3 calc(33.33% - 20px); /* 3 items per row with 10px margin on each side */
    margin: 10px; /* Space around each item */
}
.decorative-line {
    height: 4px; /* Height of the line */
    background: linear-gradient(to right, #000000, transparent, #af1414); /* Gradient effect */
    margin: 1px 0; /* Space above and below the line */
    width: calc(100%);
}
h3{
    font-family:Arial, Helvetica, sans-serif;
    padding: 10px;
    text-align: center;
    border-radius: 12px;
  font-size:20px;
      }




h2{
    color: rgb(121, 21, 21);
    font: oblique;
    font-size: 25px;
    text-decoration:double;
    
}

/* Main container */
.sign-document-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
}

/* PDF Viewer Section */
.pdf-viewer {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  height: 600px; /* Adjust as needed */
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

/* Signature Section */
.signature-section {
  text-align: center;
}

/* Signature pad */
.signature-pad {
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

/* Submit Button */
.submit-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #45a049;
}
