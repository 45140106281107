/* Navbar Styles */
.navbar {
    display: flex; /* Flexbox for layout */
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center items vertically */
    padding: 10px 20px; /* Padding for the navbar */
    background: linear-gradient(135deg, #42a5f5, #1e88e5); /* Gradient background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    position: sticky; /* Stay at the top on scroll */
    top: 0; /* Align to the top */
    z-index: 1000; /* Ensure it stays above other content */
}

/* Navbar Brand Styles */
.navbar-brand {
    font-size: 1.8rem; /* Brand font size */
    color: white; /* Brand text color */
    font-weight: bold; /* Bold brand text */
    text-decoration: none; /* Remove underline */
}

/* Navbar Links Styles */
.navbar-links {
    display: flex; /* Flexbox for links */
    gap: 20px; /* Space between links */
}

/* Individual Link Styles */
.navbar-link {
    color: white; /* Link text color */
    text-decoration: none; /* Remove underline */
    font-size: 1rem; /* Font size for links */
    padding: 8px 15px; /* Padding for links */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
}

/* Link Hover Effect */
.navbar-link:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Lighten background on hover */
    transform: scale(1.05); /* Slightly scale up */
}

/* Responsive Design */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column; /* Stack items vertically on small screens */
        align-items: flex-start; /* Align to the left */
    }

    .navbar-links {
        flex-direction: column; /* Stack links vertically */
        gap: 10px; /* Space between links */
    }
}
