.delete{
    background-color: red;
    width: 100%;

}
.emp_field{
    background-color: #898989 !important;
    color: #ffffff !important;
    font-weight: 600;
    text-align: center;
}

/* Confirmation Modal Styles */
.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.modal-content h3 {
    margin-bottom: 20px;
    font-size: 18px;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
}

.confirm-button {
    background-color: #e74c3c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirm-button:hover {
    background-color: #c0392b;
}

.cancel-button {
    background-color: #2ecc71;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-button:hover {
    background-color: #27ae60;
}