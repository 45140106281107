.new-form-container {
    left: 25%;
    position: fixed;
    top: 0; /* Fixes the top position */
    width: 60%; /* Adjust width as needed */
    max-height: 100vh; /* Prevents overflow beyond the viewport */
    overflow-y: auto; /* Allows scrolling down if the content is too long */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.new-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-header {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.form-section-header {
    font-size: 20px;
    margin: 20px 0 10px 0;
}

.form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Maintain gap between items */
    margin-bottom: 20px;
}
.vowahl{
    width: 50px;
}


/* Adjusting the input fields to allow for more items per row */
.form-input {
    flex: 1 1 calc(33% - 10px); /* Three items per row (adjust as needed) */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #292828;
    border-radius: 5px;
    box-sizing: border-box; /* Ensure padding is included in the width */
   
}

/* Full-width for email input */
.form-input[type='email'] {
    flex: 1; /* Full width */
    width: 80%;
    align-items: center;
}

/* Responsive adjustments */
@media (max-width: 900px) {
    .form-input {
        flex: 1 1 calc(50% - 10px); /* Two items per row on medium screens */
    }
}

@media (max-width: 600px) {
    .form-input {
        flex: 1 1 100%; /* Stack inputs on smaller screens */
    }
}

.checkbox-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.checkbox-label {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.form-checkbox {
    margin-right: 10px;
    font-size: 18px;
}

.submit-button {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 18px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #218838;
}

.head-Big{
    margin: auto;
    margin-top: 10%;
    text-align: center;
    color: rgb(255, 255, 255);
}


.person2{
  
        max-width: 100%;
        padding: 15px;
        border: 2px solid transparent;
        border-radius: 20px;
        font: 1em sans-serif bold;
        background: linear-gradient(145deg, #ffffff, #e6e6e6);
        box-shadow: 
            0 10px 20px rgba(0, 0, 0, 0.2), /* Subtle outer shadow */
            0 6px 8px rgba(0, 0, 0, 0.1),   /* Secondary shadow for depth */
            inset 0 1px 4px rgba(255, 255, 255, 0.5), /* Inner light shadow */
            inset 0 -4px 12px rgba(0, 0, 255, 0.2);  /* Inner soft glowing shadow */
    
        background: linear-gradient(145deg, #dbdbff, #c7c7ff);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    
    .person2:hover {
        transform: translateY(-10px); /* Lift effect on hover */
        box-shadow: 
            0 20px 40px rgba(0, 0, 0, 0.3), /* Stronger outer shadow */
            inset 0 4px 10px rgba(255, 255, 255, 0.6), /* Enhanced inner glow */
            inset 0 -8px 20px rgba(0, 0, 255, 0.3); /* Enhanced inner glow */
        background: linear-gradient(145deg, #ffffff, #ffe6ff); /* Magic-like glow effect */
    }
    

    .client-options {
        position: absolute;
       
        right:  0;
        width: 30%;
        top: 15%;
        padding: 0;
        list-style: none;
        border: 1px solid #ddd;
        border-top: none;
        height: 170px; /* Limit height if there are many options */
        overflow-y: auto;
        background-color: #fff;
        z-index: 1000; /* Ensure it appears above other elements */
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    .client-options li {
        padding: 8px;
        cursor: pointer;
    }
    
    .client-options li:hover {
        background-color: #f0f0f0;
    }
    .margin-bot{
        margin-bottom: 80px;

        border: 2px solid red;
        max-width: 100%;
        padding: 15px;
       
        border-radius: 20px;
        font: 2em sans-serif bold;
        background: linear-gradient(145deg, #ffffff, #e6e6e6);
        box-shadow: 
            0 10px 20px rgba(0, 0, 0, 0.2), /* Subtle outer shadow */
            0 6px 8px rgba(0, 0, 0, 0.1),   /* Secondary shadow for depth */
            inset 0 1px 4px rgba(255, 255, 255, 0.5), /* Inner light shadow */
            inset 0 -4px 12px rgba(0, 0, 255, 0.2);  /* Inner soft glowing shadow */
    
        background: linear-gradient(145deg, #dbdbff, #c7c7ff);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }